export const GAT_STRINGS = {
  GAT_DESKTOP_TEXT: 'Global Auditing Tool',
  GAT_DESKTOP_TEXT_STR_ID: 'gat_desktop_text',
  GAT_MOBILE_TEXT_STR_ID: 'gat_mobile_text',
  GAT_MOBILE_TEXT: 'GAT',
  GAT_WELCOME_TEXT:
    'Welcome to Amazon\'s new Global Auditing Tool. This platform allows Amazon to track and benchmark supplier performance on a global scale. Our aim is to drive continuous improvement and to foster excellence through data-driven progress. To access the system, Amazon team members please select the "Amazon Users" login. For vendors, click "Supplier Users" login',
  GAT_INTERNAL_USER_BUTTON_TEXT: 'Amazon Users',
  GAT_EXTERNAL_USER_BUTTON_TEXT: 'Supplier Users',
  GAT_FOOTER_TEXT: 'gat_footer_text',
  GAT_ACCESS_NAV_TEXT: 'gat_nav_access_text',
  GAT_SIGN_OUT_TEXT: 'gat_sign_out_button_text',
  GAT_FAQ_TEXT: 'gat_faq_text',
  GAT_ERROR_BOUNDARY_TEXT: 'gat_error_boundary_text',
  GAT_PRIVACY_NOTICE: 'gat_privacy_notice',
  GAT_HELP_WIKI: 'gat_help_wiki',
};
