import { axios } from 'src/lib/axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
  APPLICATION_JSON,
  CONTENT_TYPE,
  BACKEND_PATHS,
} from 'src/constants/backend-paths';
import { CSRF_TOKEN_COOKIE } from 'src/constants/common';

interface ApiClient {
  getCsrfToken(): Promise<any>;
  get(path: string): Promise<any>;
  post(
    path: string,
    body: any,
    abortController?: AbortController,
  ): Promise<any>;
  put(path: string, body: any): Promise<any>;
  patch(path: string, body: any): Promise<any>;
  delete(path: string): Promise<any>;
}

const refreshCsrfTokenIfExpired = async () => {
  const token = getCookie(CSRF_TOKEN_COOKIE);
  if (token == null) {
    await OrchestratorApiService.getCsrfToken();
  }
};

const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() ?? null;
  return null;
};

export const OrchestratorApiService: ApiClient = {
  async getCsrfToken() {
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.get(BACKEND_PATHS.CSRF_REFRESH, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
        'anti-csrftoken-a2z-request': true,
      },
    });
  },

  async get(path) {
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.get(path, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    });
  },

  async post(path, body, abortController: AbortController) {
    await refreshCsrfTokenIfExpired();
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.post(path, body, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
      signal: abortController?.signal,
    });
  },

  async put(path, body) {
    await refreshCsrfTokenIfExpired();
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.put(path, body, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    });
  },

  async patch(path, body) {
    await refreshCsrfTokenIfExpired();
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.patch(path, body, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    });
  },

  async delete(path) {
    await refreshCsrfTokenIfExpired();
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.delete(path, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    });
  },
};
